import { axios, API_ROUTES } from "@/config";

export default {
  async save(data) {
    let result = await axios.post(`${API_ROUTES.verifyTelephone.save}`, data);

    return result;
  },

  async validateCode(data) {
    let result = await axios.post(
      `${API_ROUTES.verifyTelephone.validateCode}`,
      data
    );

    return result;
  },
};
